<template>
  <el-container>
    <el-header><h1 class="yabiao-font">{{appName}}</h1></el-header>
    <el-main>
      <div class="hello">
          <p>让你不再为</p>
          <p>- 搬家东西太杂，收拾太累</p>
          <p>- 换季东西多，家里没空间</p>
          <p>- 兴趣，周边，收藏没地方放</p>

          <p><b>【致个人居家用户】</b></p>
          <p>———————————————————————</p>
          <p>如果你符合以下的一项或几项，或许你可以考虑使用【Totok】，相信有Totok的会更幸福。</p>
          <p>1.住着月租几万房子，东西堆的像仓库，别说生活质量了，经常找东西就要找半天</p>
          <p>2.每个星期都花了大量的时间（金钱）在整理上，但是保持不了多久</p>
          <p>3.在家很难集中注意力，宁愿去咖啡店工作看书，朋友一个电话就飞奔出去了</p>
          <p>4.家里明明有收纳间，里面永远是满的，也不知道装了什么</p>
          <p>5.三代同堂阖家欢乐，但是他们什么东西都不肯丢出去</p>
          <p>6.孕期的物品，小孩的衣服玩具，已经快扑出来了</p>
          <p><b>【致中小企业公司用户】</b></p>

          <p>———————————————————————</p>
          <p>在寸土寸金办公楼奋斗的你，把一年都不知道用不用的到的各种文件堆存在动辄10元/平/天的办公室，</p>
          <p>有时更是一天到晚忙于处理寻找各种物品，给各个客户服务商发样品/物品。存存已经帮助大量的行政工作者提高效率，让你</p>
          <p>管理的物资可视化：App上一目了然，可以搜索</p>
          <p>一键下单上门揽收，一键下单送到你指定的位置</p>

          <p><b>关于Totok】</b></p>
          <p>———————————————————————</p>
          <p>Totok 由90后的程序员开发，自从组建了家庭，有了孩子后，家里的物品越来越多。衣服、物品等等到处都是。</p>
          <p>每次找起来非常麻烦。存放的时间过长自己很难记得存放了什么东西。为了解决这个问题Totok诞生了。</p>
      </div>
    </el-main>
    <el-footer>
      <el-link type="primary" href="https://beian.miit.gov.cn" target="_blank">粤ICP备2024247029号</el-link>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      appName: 'Totok | 储物助手'
    }
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.yabiao-font {
  font-family: 'YaHei', 'Microsoft YaHei', sans-serif;
}
.el-header {
    background-color: #ffffff;
    color: #333;
    text-align: center;
    top: 0px;
    left: 0px;
    right: 0px;
    line-height: 100px;
    position: absolute;
  }

  .el-footer {
    background-color: #ffffff5e;
    color: #333;
    text-align: center;
    bottom: 0px;
    left: 0px;
    right: 0px;
    line-height: 60px;
    padding: 10px;
    overflow-y: auto; /* 当内容过多时y轴出现滚动条 */
    position: absolute;
  }
  
  .el-main {
    background-color: #ffffff;
    color: #333;
    text-align: center;
    position: absolute;
    top: 100px;
    left: 0px;
    bottom: 60px;
    right: 0px;  /* 距离右边0像素 */
    padding: 10px;
    overflow-y: auto; /* 当内容过多时y轴出现滚动条 */
    /* background-color: red; */
  }
</style>
